/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Getter, Mutation, Action, State} from 'vuex-class'
import { Support } from '@/store/support/types'

const namespace: string = 'support';

export default class HowMakeOrderPage extends Vue {
	@State('support') support: Support | undefined;

	onSubmit() {
		this.$router.push({ name: 'shop-model-choice' }).then(r => r);
	}
}
